/* ==================================
   #SAY EDITOR
   ================================== */

/**
 * A base theme for the say editor
 */

/**
 * CONTENTS
 * ---
 * SETTINGS: Project settings and variables
 * TOOLS: Mixins
 * COMPONENTS: Components (namespace: '.say-...')
 */


// VARIABLES
@import "ember-rdfa-editor/s-colors";
@import "ember-rdfa-editor/s-settings";
@import "ember-rdfa-editor/s-theme";


// TOOLS
@import "ember-rdfa-editor/t-font-size";
@import "ember-rdfa-editor/t-sass-mq";


// COMPONENTS
@import "ember-rdfa-editor/c-button";
@import "ember-rdfa-editor/c-checkbox";
@import "ember-rdfa-editor/c-container";
@import "ember-rdfa-editor/c-content";
@import "ember-rdfa-editor/c-editor";
@import "ember-rdfa-editor/c-editor-hints";
@import "ember-rdfa-editor/c-help-text";
@import "ember-rdfa-editor/c-icon";
@import "ember-rdfa-editor/c-input";
@import "ember-rdfa-editor/c-label";
@import "ember-rdfa-editor/c-modal";
@import "ember-rdfa-editor/c-switch";
@import "ember-rdfa-editor/c-toast";
@import "ember-rdfa-editor/c-toolbar";

// PLUGIN COMPONENTS
@import "ember-rdfa-editor/c-card";
@import "ember-rdfa-editor/c-link-list";
@import "ember-rdfa-editor/c-loader";
@import "ember-rdfa-editor/c-link";
@import "ember-rdfa-editor/c-pagination";
@import "ember-rdfa-editor/c-form";
@import "ember-rdfa-editor/c-article";
@import "ember-rdfa-editor/c-alert";


// RDFA AND ANNOTATION COMPONENTS
@import "ember-rdfa-editor/c-annotation";
@import "ember-rdfa-editor/c-annotation-hover";
@import "ember-rdfa-editor/c-annotation-flag";
@import "ember-rdfa-editor/c-annotation-content-nl";
@import "ember-rdfa-editor/c-annotation-content-en";
@import "ember-rdfa-editor/c-annotation-sidebar";


// THIRD PARTY PLUGIN STYLE
@import "ember-rdfa-editor/p-flatpickr";


// UTILITIES
@import "ember-rdfa-editor/u-align-text";
@import "ember-rdfa-editor/u-hide";

// TEMPORARY STYLES
@import "ember-rdfa-editor/shame";
