/* ==================================
   #SAY EDITOR HINTS
   ================================== */

.say-editor-hints {
  display: block;
  position: relative;
}

  .say-editor-hints__list {
    position: relative;
    width: 100%;

    @include mq(medium) {
      width: auto;
      max-width: 100%;
    }
  }

  .say-editor-hints__list-item {
    margin-bottom: $say-unit-small;
  }
