/* ==================================
   #TOAST
   ================================== */

.say-toast {
  @include say-font-size($say-h6,1.3);
  display: flex;
  align-items: center;
  padding: $say-unit-tiny $say-unit-small;
  color: $say-white;
  border-radius: .2rem;
  background-color: $say-neutral-800;

  &.is-loaded .say-icon {
    stroke: $say-success-500;
    fill: $say-success-500;
  }

  &.is-loaded {
    animation-name: dissappear;
    animation-iteration-count: 1;
    animation-duration: .3s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  @include mq(medium) {
    @if $say-paper == true and $say-paper-sidebar == true {
      padding: $say-unit-tiny 0;
      color: $say-neutral-700;
      background-color: transparent;
    }
  }
}

@keyframes dissappear {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
