/* ==================================
   #SAY EDITOR STYLES
   ================================== */

.say-editor {
  background-color: $say-paper-background;
  padding: $say-editor-padding $say-editor-padding/2;
  width: 100%;
  position: relative;

  @if $say-paper == true {
    background-color: $say-editor-background;
  }

  @include mq(small) {
    padding: $say-editor-padding;
  }

  @include mq(medium) {
    @if $say-paper == true {
      @if $say-paper-sidebar == true {
        padding-right: 30%;
      }
    }
  }

  @include mq(large) {
    &.rdfa-annotations-hover {
      padding-left: $say-annotation-width;
    }

    @if $say-paper == true {
      @if $say-paper-sidebar == true {
        padding-right: 25%;
      }
    }
  }
}

  .say-editor__paper {
    position: relative;
    width: 100%;
    min-height: $say-paper-min-height;

    &:focus {
      outline: none;
    }

    @if $say-paper == true {
      background-color: $say-paper-background;
      padding: $say-paper-padding;
      box-shadow: $say-paper-box-shadow;
    }
  }

  .say-editor__paper--disabled {
    background-color: $say-editor-background;
    color: $say-neutral-700;
  }

  .say-editor__inner {
    position: relative;
    min-height: $say-paper-min-height;

    // Remove focus styles
    &:focus {
      outline: 0;
    }

    // Add max-width when paper view is disabled
    @if $say-paper == false {
      * {
        max-width: 120ch;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @if $say-paper == true {
      min-height: $say-paper-min-height - $say-paper-padding * 2;
    }
  }
