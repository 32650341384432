.folder {
    color: blue;
}

.file, .folder, .subject, .type {
    cursor: pointer;
}

.folder::before {
    content: "\25B6";
    margin-right: 8px;
}

ul {
    list-style-type: none;
    margin-left: 15px;
}

.hidden {
    display: none;
}

.selected.folder {
    background-color: blue;
    color: white;  
}

.selected {
    background-color: red;
    color: white;
}

button:disabled, button:disabled:hover {
    background-color: #cccccc;
    color: #666666;
    border: none;
    cursor: not-allowed;
}

.profile {
    border-radius: 100%;
    width: 50px;
    height: 50px;
}

.profile-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 70px;
}

span.error {
    color: red;
    margin-top: 0;
}

.input-wrapper {
    margin-bottom: 15px;
}