/* ==================================
   #SAY TOOLBAR
   ================================== */

.say-switch {
  display: inline-flex;
  position: relative;
  align-items: center;
  margin: $say-unit-small $say-unit-tiny;
}

  /* Hide the checkbox input (only needed for `:checked` property) */
  .say-switch__input {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  /* Switch */
  .say-switch__label {
    @include say-font-size($say-base);
    color: $say-switch-label-color;
    display: block;
    padding-left: $say-unit + $say-unit-tiny;
    width: $say-unit;
    overflow: hidden;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }

    &:before {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: $say-unit;
      height: $say-unit-small;
      background-color: $say-switch-color;
      border-radius: 9999em;
      transition: background-color $say-transition;
    }

    &:after {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: $say-unit-small;
      height: $say-unit-small;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 0 .2rem rgba($say-neutral-900, 0.45);
      transition: left $say-transition;
    }

    @include mq(small) {
      width: auto;
    }
  }

  .say-switch__input:focus + .say-switch__label {
    outline: $say-outline;
  }

  /* When input is checked */
  .say-switch__input:checked + .say-switch__label {
    &:before {
      background-color: $say-switch-active-color;
    }

    &:after {
      left: $say-unit-small;
    }
  }
