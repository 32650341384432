/* ==================================
  #ALERT
  ================================== */

.say-alert {
  display: flex;
  color: $say-gray-800;
  padding: $say-unit;
  margin-bottom: $say-unit;
  background-color: $say-gray-200;
}

.say-alert__icon {
  background-color: $say-white;
  border-radius: $say-unit-large;
  height: $say-unit-large - .1rem; // compensate for visual distortion of perfect circle
  width: $say-unit-large;
  margin-right: $say-unit-large / 2;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .say-icon {
    height: 2rem - .1rem; // compensate for visual distortion of perfect circle
    width: 2rem;
    bottom: 0;
  }
}

.say-alert__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.say-alert__title {
  font-weight: $say-medium;
}
