/* ==================================
   #LINK LISTS
   ================================== */

.say-link-list {
  overflow: auto;
  height: 100%;
}

.say-link-list__item {
  @include say-font-size($say-h6);
  list-style-type: none;
  position: relative;

  & + & {
    border-top: .1rem solid $say-neutral-200;
  }
}

.say-link-list__item--insert {
  padding-left: $say-unit-large;
}

.say-link-list__link {
  @include say-font-size($say-base,1.3);
  font-family: $say-font;
  border: 0;
  outline: 0;
  appearance: none;
  display: block;
  width: 100%;
  padding: $say-unit-small;
  position: relative;
  color: $say-neutral-700;
  text-decoration: underline;
  text-decoration-color: $say-neutral-300;
  transition: color $say-transition;
  text-align: left;

  &:hover,
  &:focus {
    color: $say-neutral-600;
    text-decoration: none;
  }

  &:focus {
    outline: $say-outline;
    outline-offset: -.6rem;
  }
}

.say-link-list__link--arrow {
  @include say-font-size($say-h6);
  position: relative;
  padding: $say-unit-small $say-unit-large + $say-unit-tiny $say-unit-small $say-unit-small;

  .say-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $say-unit-small + $say-unit-tiny;
  }

  strong {
    display: block;
    margin-top: $say-unit-tiny;
  }
}

.say-link-list__insert {
  color: $say-primary-600;
  appearance: none;
  position: absolute;
  left: $say-unit-tiny;
  top: $say-unit-tiny;
  height: calc(100% - #{$say-unit-small});
  width: $say-unit-large - $say-unit-tiny;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .2rem;
  transition: color $say-transition, background-color $say-transition;

  &:hover,
  &:focus {
    background-color: $say-neutral-100;
  }
}

.say-link-list__content--capped {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-height: $say-unit * 4.6;
  overflow: hidden;
}



// Disable fixed scrollbars on html element > @TODO: remove with new version of appuniversum
.say-link-list--dark {
  .say-link-list__item--capped a.au-c-link.au-c-link--secondary {
    color: $say-neutral-800;
    text-decoration: underline;
  }

  .say-link-list__item--capped:nth-child(4),
  .say-link-list__item--capped:nth-child(5) {
    display: none;
  }
}
