/* ==================================
   #SAY ANNOTATIONS HOVER
   ================================== */

.rdfa-annotations-hover:not(.show-rdfa-blocks) {
  overflow: hidden;

  // Make block elements relative
  div,
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  ul:not(ul ul),
  ol:not(ol ol),
  [property="dc:title"],
  [property="dc:title foaf:name"],
  [typeof~="besluit:Besluit"],
  [property="ext:stemmingTable"],
  [property="ext:insertStemmingText"],
  [property="ext:aanwezigenTable"],
  [property="ext:insertAanwezigenText"],
  [property="prov:generated"] > [property="besluit:motivering"],
  [property="prov:generated"] > [property="prov:value"],
  [typeof="besluit:Zitting"],
  [property="openbaar"],
  [property^="ext"]:not([property="ext:heeftAfwezigeBijAgendapunt"]):not(span),
  [data-editor-highlight="true"]:not(ol):not(ul):not(li):not(span),
  [typeof="http://data.vlaanderen.be/ns/besluit#Besluit http://data.vlaanderen.be/ns/besluit#AanvullendReglement"],
  [typeof="besluit:Artikel"],
  [typeof="mobiliteit:Opstelling"] {
    position: relative;

    &:before {
      height: 100%;
      top: 0;
    }
  }

  // Style before and after elements
  .mark-highlight-manual,
  [typeof]:not([typeof="foaf:Document"]),
  [property],
  [data-type],
  [data-editor-highlight="true"]:not([contenteditable=""]) {
    // &:hover {
    //   border-bottom-color: tint($say-neutral-600,20);
    // }

    &:before {
      position: absolute;
      transition: opacity $say-easing .5s, left $say-easing .2s;
      right: calc(100% + #{$say-annotation-offset});
      opacity: 0;
    }

    &:before {
      @include say-font-size($say-tiny,1.2);
      content:  attr(property) " " attr(typeof) " " attr(data-type);
      display: block;
      margin-top: .2rem;
      padding-right: $say-annotation-gutter + $say-unit-small;
      margin-right: -$say-annotation-gutter + $say-unit-tiny;
      width: $say-annotation-width-content;
      font-family: $say-font;
      font-weight: $say-medium;
      letter-spacing: .01rem;
      text-transform: uppercase;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $say-neutral-600;
      background-color: $say-neutral-100;
      pointer-events: none;
      overflow: hidden;
      min-height: 2rem;
      border-right: 1px dashed $say-neutral-300;
      border-top: 1px dashed $say-neutral-300;
      z-index: $say-z-index-gamma;
      background: linear-gradient(
        to right,
          $say-neutral-100,
          $say-neutral-100 calc(100% - #{$say-annotation-gutter + $say-unit-tiny}),
          transparent calc(100% - #{$say-annotation-gutter + $say-unit-tiny}),
          transparent 100%
      );
    }

    // &[data-editor-position-level]:before,
    // &:hover:before {
    //   right: calc(100% + #{$say-annotation-offset});
    //   opacity: 1;
    // }

    // // Bring highlighted annotation to the front
    // &[data-editor-position-level]:before {
    //   z-index: $say-z-index-alpha;
    // }

    // &[data-editor-position-level]:before {
    //   border-top-style: solid;
    //   border-right-style: solid;
    // }

    // &[data-editor-position-level="0"]:before {
    //   z-index: $say-z-index-gamma;
    // }

    // // Hide right border when not selected
    // &:not([data-editor-position-level]):hover:before,
    // &:not([data-editor-position-level]):before {
    //   border-right: 0;
    // }
  }

  // No right border on inline elements
  // [property="eli:cites"]:hover:before,
  // [property="eli:cites"][data-editor-position-level]:before,
  // [style="display: inline;"]:hover:before,
  // [style="display: inline;"][data-editor-position-level]:before {
  //   border-right: 0 !important;
  // }

  // Add highlight help text
  // span.mark-highlight-manual:before,
  // span.mark-highlight-manual:hover:before {
  //   content: "Fill in";
  //   display: inline;
  //   width: auto;
  //   line-height: 1;
  //   padding-top: $say-unit-tiny/2;
  //   padding-bottom: $say-unit-tiny/1.6;
  //   padding-left: $say-unit-tiny/2;
  //   padding-right: $say-unit-tiny;
  //   color: $say-warning-600;
  //   background: $say-warning-200;
  //   z-index: 5;
  //   border: 1px solid $say-warning-300 !important;
  //   margin-right: -$say-unit;
  // }

  // Hide nested lump node annotations
  // [property="http://lblod.data.gift/vocabularies/editor/isLumpNode"] [property="http://lblod.data.gift/vocabularies/editor/isLumpNode"] {
  //   &:before,
  //   &:hover:before {
  //     display: none;
  //   }
  // }

  // Hide empty properties or flagged as noHighlight
  // [property=""],
  // [typeof=""],
  // // [property="eli:realizes"],
  // [property="http://mu.semte.ch/vocabularies/ext/noHighlight"],
  // [property="http://mu.semte.ch/vocabularies/ext/noHighlight"] {
  //   border-bottom: 0 !important;

  //   &:before,
  //   &[data-editor-position-level]:before,
  //   &[data-editor-position-level]:hover:before,
  //   &:hover:before {
  //     display: none !important;
  //     content: '' !important;
  //     display: none !important;
  //     opacity: 0 !important;
  //   }
  // }

  @include mq($until: large) {
    // Hide all hover annotations on small screens
    .mark-highlight-manual,
    [typeof]:not([typeof="foaf:Document"]),
    [property],
    [data-type],
    [data-editor-highlight="true"] {
      &:before {
        display: none !important;
      }
    }
  }
}
