/* ==========================================================================
   #TEXT ALIGNMENT
   ========================================================================== */

/**
 * Utility classes to align text. Use text alignment like this:
 *
 *   <p class="say-u-text-right">
 *      text
 *   </p>
 *
 *   <div class="say-u-text-right">
 *      <button class="say-button">
 *        Button
 *      </button>
 *   </div>
 */

/* Classes
  ========================================================================== */

.say-u-text-right {
  text-align: right;
}

.say-u-text-center {
  text-align: center;
}

.say-u-text-left {
  text-align: left;
}
