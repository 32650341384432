/* ==========================================================================
   #SHAME
   Temporary css
   Some parts will be reused, but most of them will not.
   ========================================================================== */

.say-typography {
  // Hide empty text elements
  #text {
    display: none !important;
  }

  .annotation:hover {
    background-color: transparent !important; // override annotation hover background
  }

  // Not sure what this does
  mark {
    cursor: pointer;
  }

  mark table {
    font-weight: normal;
    border: 4px solid $say-warning-400;

    &:active {
      opacity: .5;
    }
  }
}

// Not sure if this is used
.internal-component {
  display: block;
  width: 50%;
  margin: 5px auto;
  border: 1px solid #333;
}

.internal-component .close {
  float: right;
  margin: 5px;
}

// Legacy modal style fixes
.say-card .modal-dialog {
  position: relative;
  top: 0;
  left: 0;
  box-shadow: none;
  transform: none;
  padding: 0;
  width: 100%;
}

// Temporary fix for nested cards
.say-card .say-card {
  box-shadow: none;
  position: relative;
}

.say-card ul li div.u-hr {
  display: none;
}

.say-card .modal-dialog__header {
  @include say-font-size($say-h6);
  color: $say-neutral-800;
  padding: $say-unit-small;
  border-bottom: .1rem solid $say-neutral-200;

  * {
    margin-bottom: 0;
  }

  * + * {
    margin-top: $say-unit-small;
  }

  .grid {
    align-items: center;
  }

  .col--2-12.u-align-right {
    margin-top: 0;
    padding-right: $say-unit-tiny;
  }
}

.say-card .modal-dialog__content {
  padding: $say-unit-small;
}

.say-card .modal-dialog__content--sectioned {
  padding: 0;
}

.say-card .modal-dialog__content--sectioned > * {
  max-height: 50vh;
}

.say-card .modal-dialog__content--sectioned .modal-dialog__footer {
  border-top: 1px solid $say-neutral-200;
}

.say-card .modal-dialog__footer {
  padding: $say-unit-small;

  button {
    margin-bottom: 0;
  }
}

.say-card .link-list {
  @media screen and (max-width:767px) {
    margin-bottom: 0 !important;
  }
}

.say-card .link-list.link-list--bordered {
  .link-list__item.link-list__item--sidenotes a {
    padding: $say-unit-small !important;
  }

  .link-list__item.link-list__item--sidenotes a:hover {
    background-color: $say-neutral-100;
  }

  .link-list__item__sidenote.uppercase {
    @include say-font-size($say-tiny,1.3);
    color: $say-neutral-600;
    padding: 0;
  }
}

.say-card .modal-dialog__link-list {
  padding: $say-unit-small;
  width: 100%;
  overflow: hidden;
}


.modal-dialog.modal-dialog--sectioned .modal-dialog__content.modal-dialog__content--sidebar-grid {
  padding: 0;
  overflow: hidden;

  > .grid {
    max-height: 60vh;
    overflow: hidden;
  }

  > .grid > div:first-child {
    max-height: 60vh;
    overflow-y: auto;
  }

  > .grid > div:nth-child(2) {
    max-height: 60vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > div:first-child {
      overflow-y: auto;
      flex-grow: 1;
    }

    > div:nth-child(2) {
      flex-grow: 0;
    }
  }
}

.say-border-bottom {
  border-bottom: .1rem solid $say-neutral-300;
}
