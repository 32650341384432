/* ==================================
   #ICONS
   ================================== */

.say-icon {
  display: inline-block;
  width: $say-icon-size;
  height: $say-icon-size;
  position: relative;
  vertical-align: baseline;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}


/* Modifiers
  ========================================================================== */

.say-icon--medium {
  width: $say-icon-size-medium;
  height: $say-icon-size-medium;
}

.say-icon--large {
  width: $say-icon-size-large;
  height: $say-icon-size-large;
  vertical-align: center;
}

.say-icon--left {
  margin-right: $say-unit-tiny;
}

.say-icon--right {
  margin-left: $say-unit-tiny;
}

.say-icon--rotate {
  animation-name: iconRotate;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  transform-origin: center;
}

@keyframes iconRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
