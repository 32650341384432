/* ==================================
  #SAY ANNOTATIONS CONTENT - NL locale
  ================================== */

.rdfa-annotations [data-editor-highlight="true"],
.rdfa-annotations [property],
.rdfa-annotations [typeof]:not([typeof="foaf:Document"]),
.rdfa-annotations [data-type],
.rdfa-annotations .mark-highlight-manual,
.rdfa-annotations.show-rdfa-blocks,
.rdfa-annotations:not(.show-rdfa-blocks) {

  /* -------------------- Guiding tags ------------------------- */

  [property^="ext:insert"]:before,
  [data-editor-highlight="true"]:before,
  [data-editor-highlight="true"]:not([contenteditable=""]):before {
    content: "Selecteer de juiste optie";
  }

  span.mark-highlight-manual:before {
    content: "Vul in";
  }

  /* -------------------- General tags ------------------------- */
  [typeof="besluit:Zitting"]:before {
    content: "Zitting";
  }

  [datatype="xsd:date"]:before {
    content: "Datum";
  }

  [property="ext:datumVerkiezing"]:before {
    content: "Datum verkiezing";
  }

  [property="ext:datumGoedkeuringVerkiezing"]:before {
    content: "Datum goedkeuring verkiezing";
  }

  [typeof="besluit:Bestuurseenheid"]:before {
    content: "Bestuurseenheid";
  }

  [property="ext:scopedBestuursorgaanText"]:before {
    content: "Selecteer het bestuursorgaan";
  }

  [typeof="besluit:Bestuursorgaan"]:before {
    content: "Bestuursorgaan";
  }

  /* -------------------- Zitting ------------------------- */

  [typeof="besluit:Zitting"] > [property="dc:title"]:before {
    content: "Titel";
  }

  [property="besluit:geplandeStart"]:before {
    content: "Geplande start";
  }

  [property="prov:startedAtTime"]:before {
    content: "Tijdstip start zitting";
  }

  [property="prov:endedAtTime"]:before {
    content: "Tijdstip einde zitting";
  }

  [property="prov:atLocation"]:before {
    content: "Locatie";
  }

  [property="dc:description"]:before {
    content: "Omschrijving";
  }

  [property="http://data.vlaanderen.be/ns/besluit#isGehoudenDoor"]:before {
    content: "Bestuursorgaan";
  }

  /* Aanwezigen en types */
  [property="ext:aanwezigenTable"]:before {
    content: "Aanwezigenlijst";
  }

  [property="ext:insertAanwezigenText"]:before {
    content: "Duid aanwezigen aan";
  }

  [property="besluit:heeftAanwezigeBijStart"]:before {
    content: "Aanwezige bij start van de zitting";
  }

  [property="ext:heeftAfwezigeBijStart"]:before {
    content: "Afwezige bij start van zitting";
  }

  [typeof="http://www.w3.org/ns/person#Person"][property="besluit:heeftVoorzitter"]:before {
    content: "Voorzitter van de zitting";
  }

  [property="ext:secretarisTemporaryAsText"]:before,
  [typeof="http://www.w3.org/ns/person#Person"][property="besluit:heeftSecretaris"]:before {
    content: "Secretaris van de zitting";
  }

  [typeof="ext:BesluitGemeenteraadsledenAfstandMandaat"]:before {
    content: "Afstand van mandaat";
  }

  [typeof="ext:BesluitGemeenteraadsledenVerkiesbaarheidsvoorwaarden"]:before {
    content: "Verkiesbaarheidsvoorwaarden";
  }

  [typeof="ext:BesluitGemeenteraadsledenOnverenigbaarheden"]:before {
    content: "Onverenigbaarheden";
  }

  [typeof="ext:BesluitGemeenteraadsledenVerhindering"]:before {
    content: "Verhindering";
  }

  /* -------------------- Agendapunt ------------------------- */
  [property="ext:agendapuntenTable"]:before {
    content: "Agenda";
  }

  [property="ext:insertAgendaText"]:before {
    content: "Beheer agenda";
  }

  [property="ext:behandelingVanAgendapuntenContainer"]:before {
    content: "Behandeling agenda";
  }

  [typeof="besluit:Agenda"]:before {
    content: "Agenda"; // op hetzelfde niveau als onbestaande "heeftagenda"
  }

  [property="besluit:heeftAgendapunt"] [property="besluit:geplandOpenbaar"] + span + [property="dc:title"]:before {
    content: "Titel gepland openbaar agendapunt";
  }

  [property="besluit:heeftAgendapunt"] [property="besluit:geplandBesloten"] + span + [property="dc:title"]:before {
    content: "Titel gepland besloten agendapunt";
  }

  /* -------------------- Behandeling agendapunt ------------------------- */
  [typeof="besluit:BehandelingVanAgendapunt"]:before {
    content: "Behandeling van agendapunt"
  }

  [typeof="besluit:Besluit"]:before,
  [typeof~="besluit:Besluit"]:before,
  [typeof="besluit:Besluit ext:BesluitKlassiekeStijl"]:before,
  [typeof="besluit:Besluit ext:BesluitNieuweStijl"]:before {
    content: "Besluit";
  }

  [typeof="http://data.vlaanderen.be/ns/besluit#Besluit http://data.vlaanderen.be/ns/besluit#AanvullendReglement"]:before {
    content: "Besluit: Aanvullend reglement";
  }

  [property="dc:subject"]:before {
    content: "Onderwerp";
  }

  [property="besluit:geplandOpenbaar"]:before {
    content: "Gepland openbaar";
  }

  [property="besluit:geplandOpenbaar"][content="false"]:before {
    content: "Gepland besloten";
  }

  [property="besluit:openbaar"]:before {
    content: "Openbare behandeling"
  }

  [property="besluit:openbaar"][content="false"]:before {
    content: "Besloten behandeling"
  }

  [typeof="besluit:BehandelingVanAgendapunt"] [property="besluit:openbaar"] + [property="dc:subject"] + [typeof="besluit:Besluit"]:before {
    content: "\f06e \00A0 \00A0 Open agendapunt";
    font-family: fontawesome, flanders-sans, sans-serif;
  }

  [typeof="besluit:BehandelingVanAgendapunt"] [property="besluit:besloten"] + [property="dc:subject"] + [typeof="besluit:Besluit"]:before {
    content: "\f07e \00A0 \00A0 Besloten agendapunt";
  }

  [typeof="besluit:BehandelingVanAgendapunt"] [property="besluit:openbaar"] + [property="dc:subject"] + [typeof="besluit:Besluit mandaat:Aanstellingsbesluit"]:before {
    content: "\f06e \00A0 \00A0 Open agendapunt: aanstellingsbesluit";
    font-family: fontawesome, flanders-sans, sans-serif;
  }

  [typeof="besluit:BehandelingVanAgendapunt"] [property="besluit:besloten"] + [property="dc:subject"] + [typeof="besluit:Besluit mandaat:Aanstellingsbesluit"]:before {
    content: "\f070 \00A0 \00A0 Open agendapunt: aanstellingsbesluit";
    font-family: fontawesome, flanders-sans, sans-serif;
  }


  [typeof="mandaat:Mandataris"]:before,
  [property="ext:bekrachtigtAanstellingVan"]:before {
    content: "Aanstelling mandataris";
  }

  [property="ext:bekrachtigdOnverenigbaarheid"]:before {
    content: "Onverenigbaarheid";
  }

  [property="ext:bekrachtigdAfstandVanMandaatDoor"]:before {
    content: "Afstand mandaat";
  }

  [property="ext:bekrachtigdAfwezigheidMetKennisGeving"]:before {
    content: "Afwezig met kennisgeving";
  }

  [property="ext:bekrachtigdAfwezigheid"]:before {
    content: "Afwezig zonder kennisgeving";
  }

  [typeof="http://www.w3.org/ns/person#Person"]:before {
    content: "Persoon";
  }

  [property="besluit:heeftAanwezigeBijStart"][typeof="http://www.w3.org/ns/person#Person"]:before {
    content: "Aanwezige persoon bij start";
  }

  [property="mandaat:isBestuurlijkeAliasVan"]:before {
    content: "Mandataris";
  }

  [property="persoon:gebruikteVoornaam"]:before {
    content: "Voornaam";
  }

  [property="foaf:familyName"]:before {
    content: "Familienaam";
  }

  [property="org:holds"]:before {
    content: "Mandaat";
  }

  [property="org:role"]:before {
    content: "Rol";
  }

  [property="mandaat:start"]:before {
    content: "Start mandaat";
  }

  [property="skos:prefLabel"]:before {
    content: "Naam rol";
  }

  [property="ext:stemmingTable"]:before {
    content: "Stemming";
  }

  [property="besluit:heeftStemming"]:before {
    content: "Stemming bij behandeling van agendapunt";
  }

  [property="besluit:heeftAanwezige"]:before {
    content: "Aanwezige";
  }

  [property="ext:heeftAfwezigeBijAgendapunt"]:before,
  [property="besluit:heeftAfwezige"]:before {
    content: "Afwezige";
  }

  [typeof="besluit:Agendapunt"]:before {
    content: "Agendapunt";
  }

  [property="dc:title"]:before,
  [property="eli:title"]:before {
    content: "Titel";
  }

  [property="eli:title_short"]:before {
    content: "Citeer-opschrift (optioneel)";
  }

  [property="eli:description"]:before {
    content: "Beschrijving besluit";
  }

  [property="ext:behandelt"] > [property="dc:subject"]:before {
    content: "Onderwerp behandeling";
  }

  [property="besluit:motivering"]:before {
    content: "Motivering";
  }

  [property="eli:cites"]:before {
    content: "Citaat";
  }

  [property="prov:value"]:before {
    content: "Besluit";
  }

  [typeof~="besluit:Artikel"]:before {
    content: "Artikel";
  }

  [property="eli:has_part"][typeof="besluit:Artikel"]:before {
    content: "Artikel";
  }

  [typeof="besluit:Artikel"] [property="prov:value"]:before {
    content: "Inhoud artikel"
  }

  [property="eli:has_part"]:before {
    content: "Onderdeel";
  }

  [property="eli:number"]:before {
    content: "Nummer";
  }

  /* Mobiliteit */
  [typeof="mobiliteit:Opstelling"]:before {
    content: "Opstelling";
  }

  [property="locn:geometry"]:before,
  [typeof="locn:Geometry"]:before {
    content: "Zone"; // : " attr(resource)
  }

  [property="geosparql:asWKT"]:before {
    content: attr(content);
  }

  [property="mobiliteit:omvatVerkeersbord"]:before {
    content: "Verkeersbord";
  }

  [property="mobiliteit:grafischeWeergave"]:before {
    content: "Grafische weergave"; //: " attr(resource);
  }

  [property="mobiliteit:Verkeersbord.operationeleStatus"]:before,
  [property="mobiliteit:Opstelling.operationeleStatus"]:before {
    content: "Operationele status";
  }

  [property="mobiliteit:isBeginZone"]:before {
    content: "Duidt begin zone aan";
  }

  [property="mobiliteit:isEindeZone"]:before {
    content: "Duidt einde zone aan";
  }

  [property="mobiliteit:heeftVerkeersbordconcept"]:before {
    content: "Verkeersbord concept";
  }

  [property="mobiliteit:heeftVerkeersbordconcept"]:before {
    content: "Verkeersbord concept";
  }

  [property="mobiliteit:realiseert"]:before {
    content: "Realisatie";
  }

  /* -------------------- Stemming ------------------------- */
  [typeof="besluit:stemming"]:before {
    content: "Stemming";
  }

  [property="besluit:geheim"][content="false"]:before {
    content: "Openbare stemming";
  }

  [property="besluit:geheim"][content="true"]:before {
    content: "Geheime stemming";
  }

  [property="besluit:onderwerp"]:before {
    content: "Onderwerp stemming";
  }

  [property="besluit:gevolg"]:before {
    content: "Gevolg stemming";
  }

  [property="besluit:aantalOnthouders"]:before {
    content: "Aantal onthouders";
  }

  [property="besluit:aantalTegenstanders"]:before {
    content: "Aantal tegen-standers";
  }

  [property="besluit:aantalVoorstanders"]:before {
    content: "Aantal voor-standers";
  }

  [property="besluit:heeftStemmer"]:before {
    content: "Deelnemer stemming";
  }

  [property="besluit:heeftOnthouder"]:before {
    content: "Onthouder";
  }

  [property="besluit:heeftTegenstander"]:before {
    content: "Tegen-stander";
  }

  [property="besluit:heeftVoorstander"]:before {
    content: "Voor-stander";
  }

  [property="ext:signatories"]:before {
    content: "Ondertekenaars";
  }

  [property="ext:behandelt"] [data-editor-highlight="true"] {
    &:before {
      content: "Selecteer de juiste optie";
    }
  }

  // rewind
  [typeof="besluit:Zitting"]{
    position: relative;
  }

  [property="ext:behandelt"]:hover {
    background-color: transparent !important;
  }

  [typeof="besluit:Zitting"]:before {
    content: "Zitting";
  }

  [datatype="xsd:date"]:before {
    content: "Datum";
  }

  [property="ext:datumVerkiezing"]:before {
    content: "Datum verkiezing";
  }

  [property="ext:datumGoedkeuringVerkiezing"]:before {
    content: "Datum goedkeuring verkiezing";
  }

  [typeof="besluit:Bestuurseenheid"]:before {
    content: "Bestuurseenheid";
  }

  [property="ext:scopedBestuursorgaanText"]:before {
    content: "Selecteer het bestuursorgaan";
  }

  [typeof="besluit:Bestuursorgaan"]:before {
    content: "Bestuursorgaan";
  }

  /* -------------------- Zitting ------------------------- */
  [typeof="besluit:Zitting"] > [property="dc:title"]:before {
    content: "Titel";
  }

  [property="besluit:geplandeStart"]:before {
    content: "Geplande start van de zitting";
  }

  [property="prov:startedAtTime"]:before {
    content: "Tijdstip waarop de zitting begint";
  }

  [property="prov:endedAtTime"]:before {
    content: "Tijdstip waarop de zitting eindigt";
  }

  [property="http://data.vlaanderen.be/ns/besluit#isGehoudenDoor"]:before {
    content: "Bestuursorgaan";
  }

  [property="besluit:geplandeStart"]:before {
    content: "Geplande start";
  }

  /* Aanwezigen en types */
  [property="ext:aanwezigenTable"]:before {
    content: "Aanwezigenlijst";
  }

  [property="ext:insertAanwezigenText"]:before {
    content: "Duid aanwezigen aan";
  }

  [property="besluit:heeftAanwezigeBijStart"]:before {
    content: "Aanwezige bij start van de zitting";
  }

  [property="ext:heeftAfwezigeBijStart"]:before {
    content: "Afwezige bij start van zitting";
  }

  [typeof="http://www.w3.org/ns/person#Person"][property="besluit:heeftVoorzitter"]:before {
    content: "Voorzitter van de zitting";
  }

  [property="ext:secretarisTemporaryAsText"]:before,
  [typeof="http://www.w3.org/ns/person#Person"][property="besluit:heeftSecretaris"]:before {
    content: "Secretaris van de zitting";
  }

  /* -------------------- Agendapunt ------------------------- */
  [property="ext:agendapuntenTable"]:before {
    content: "Agenda";
  }

  [property="ext:insertAgendaText"]:before {
    content: "Beheer agenda";
  }

  [property="ext:behandelingVanAgendapuntenContainer"]:before {
    content: "Behandeling agenda";
  }

  [typeof="besluit:Agenda"]:before {
    content: "Agenda"; // op hetzelfde niveau als onbestaande "heeftagenda"
  }

  [property="besluit:heeftAgendapunt"] [property="besluit:geplandOpenbaar"] + span + [property="dc:title"]:before {
    content: "Titel gepland openbaar agendapunt";
  }

  [property="besluit:heeftAgendapunt"] [property="besluit:geplandBesloten"] + span + [property="dc:title"]:before {
    content: "Titel gepland besloten agendapunt";
  }

  /* -------------------- Behandeling agendapunt ------------------------- */
  [typeof="besluit:BehandelingVanAgendapunt"]:before {
    content: "Behandeling van agendapunt"
  }

  [typeof="besluit:Besluit"]:before,
  [typeof="besluit:Besluit ext:BesluitKlassiekeStijl"]:before,
  [typeof="besluit:Besluit ext:BesluitNieuweStijl"]:before {
    content: "Besluit";
  }

  [property="dc:subject"]:before {
    content: "Onderwerp";
  }

  [property="besluit:geplandOpenbaar"]:before {
    content: "Gepland openbaar";
  }

  [property="besluit:geplandOpenbaar"][content="false"]:before {
    content: "Gepland besloten";
  }

  [property="besluit:openbaar"]:before {
    content: "Openbare behandeling"
  }

  [property="besluit:openbaar"][content="false"]:before {
    content: "Besloten behandeling"
  }

  [typeof="besluit:BehandelingVanAgendapunt"] [property="besluit:openbaar"] + [property="dc:subject"] + [typeof="besluit:Besluit"]:before {
    content: "\f06e \00A0 \00A0 Open agendapunt";
    font-family: fontawesome, flanders-sans, sans-serif;
  }

  [typeof="besluit:BehandelingVanAgendapunt"] [property="besluit:besloten"] + [property="dc:subject"] + [typeof="besluit:Besluit"]:before {
    content: "\f07e \00A0 \00A0 Besloten agendapunt";
  }

  [typeof="besluit:BehandelingVanAgendapunt"] [property="besluit:openbaar"] + [property="dc:subject"] + [typeof="besluit:Besluit mandaat:Aanstellingsbesluit"]:before {
    content: "\f06e \00A0 \00A0 Open agendapunt: aanstellingsbesluit";
    font-family: fontawesome, flanders-sans, sans-serif;
  }

  [typeof="besluit:BehandelingVanAgendapunt"] [property="besluit:besloten"] + [property="dc:subject"] + [typeof="besluit:Besluit mandaat:Aanstellingsbesluit"]:before {
    content: "\f070 \00A0 \00A0 Open agendapunt: aanstellingsbesluit";
    font-family: fontawesome, flanders-sans, sans-serif;
  }

  [typeof="mandaat:Mandataris"]:before,
  [property="ext:bekrachtigtAanstellingVan"]:before {
    content: "Aanstelling mandataris";
  }

  [property="ext:bekrachtigdOnverenigbaarheid"]:before {
    content: "Onverenigbaarheid";
  }

  [property="ext:bekrachtigdAfstandVanMandaatDoor"]:before {
    content: "Afstand mandaat";
  }

  [property="ext:bekrachtigdAfwezigheidMetKennisGeving"]:before {
    content: "Afwezig met kennisgeving";
  }

  [property="ext:bekrachtigdAfwezigheid"]:before {
    content: "Afwezig zonder kennisgeving";
  }

  [typeof="http://www.w3.org/ns/person#Person"]:before {
    content: "Persoon";
  }

  [property="besluit:heeftAanwezigeBijStart"][typeof="http://www.w3.org/ns/person#Person"]:before {
    content: "Aanwezige persoon bij start";
  }

  [property="mandaat:isBestuurlijkeAliasVan"]:before {
    content: "Mandataris";
  }

  [property="persoon:gebruikteVoornaam"]:before {
    content: "Voornaam";
  }

  [property="foaf:familyName"]:before {
    content: "Familienaam";
  }

  [property="org:holds"]:before {
    content: "Mandaat";
  }

  [property="mandaat:start"]:before {
    content: "Start mandaat";
  }

  [property="besluit:heeftStemming"]:before {
    content: "Stemming bij behandeling van agendapunt";
  }

  [property="besluit:heeftAanwezige"]:before {
    content: "Aanwezige";
  }

  [property="ext:heeftAfwezigeBijAgendapunt"]:before,
  [property="besluit:heeftAfwezige"]:before {
    content: "Afwezige";
  }

  [typeof="besluit:Agendapunt"]:before {
    content: "Agendapunt";
  }

  [property="dc:title"]:before,
  [property="eli:title"]:before {
    content: "Titel";
  }

  [property="eli:title_short"]:before {
    content: "Citeer-opschrift (optioneel)";
  }

  [property="eli:description"]:before {
    content: "Beschrijving besluit";
  }

  [property="ext:behandelt"] > [property="dc:subject"]:before {
    content: "Onderwerp behandeling";
  }

  [property="besluit:motivering"]:before {
    content: "Motivering";
  }

  [property="eli:cites"]:before {
    content: "Citaat";
  }

  [property="prov:value"]:before {
    content: "Besluit";
  }

  [property="eli:has_part"][typeof="besluit:Artikel"]:before {
    content: "Artikel";
  }

  [typeof="besluit:Artikel"] [property="prov:value"]:before {
    content: "Inhoud artikel"
  }

  [property="eli:has_part"]:before {
    content: "Onderdeel";
  }

  [property="eli:number"]:before {
    content: "Nummer";
  }


  /* -------------------- Stemming ------------------------- */
  [typeof="besluit:stemming"]:before {
    content: "Stemming";
  }

  [property="besluit:geheim"][content="false"]:before {
    content: "Openbare stemming";
  }

  [property="besluit:geheim"][content="true"]:before {
    content: "Geheime stemming";
  }

  [property="besluit:onderwerp"]:before {
    content: "Onderwerp stemming";
  }

  [property="besluit:gevolg"]:before {
    content: "Gevolg stemming";
  }

  [property="besluit:aantalOnthouders"]:before {
    content: "Aantal onthouders";
  }

  [property="besluit:aantalTegenstanders"]:before {
    content: "Aantal tegen-standers";
  }

  [property="besluit:aantalVoorstanders"]:before {
    content: "Aantal voor-standers";
  }

  [property="besluit:heeftStemmer"]:before {
    content: "Deelnemer stemming";
  }

  [property="besluit:heeftOnthouder"]:before {
    content: "Onthouder";
  }

  [property="besluit:heeftTegenstander"]:before {
    content: "Tegen-stander";
  }

  [property="besluit:heeftVoorstander"]:before {
    content: "Voor-stander";
  }

  [property="ext:signatories"]:before {
    content: "Ondertekenaars";
  }

  // Imported HTML block
  [property="http://lblod.data.gift/vocabularies/editor/isLumpNode"]::before,
  [property="http://lblod.data.gift/vocabularies/editor/isLumpNode"]:hover::before {
    content: "Geïmporteerde data – niet aanpasbaar";
  }

  [property="http://lblod.data.gift/vocabularies/editor/isLumpNode"][data-flagged-remove="complete"]:before {
    content: "Wil je deze blok verwijderen? Druk op backspace om te bevestigen.";
  }
}

.rdfa-annotations.show-rdfa-blocks {
  .mark-highlight-manual:after,
  [data-editor-highlight="true"]:not([contenteditable=""]):after {
    content: "Actie nodig";
  }
}
