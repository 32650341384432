/* ==================================
   #BUTTONS
   ================================== */

.say-button {
  @include say-font-size($say-button-font-size);
  font-family: $say-button-font-family;
  font-weight: $say-button-font-weight;
  height: $say-button-height;
  text-decoration: none;
  text-align: center;
  appearance: none;
  display: inline-flex;
  align-items: center;
  padding: 0 $say-unit-small .1ex; // Visually center the text in the button
  border: $say-button-border solid $say-button-border-color;
  border-radius: $say-button-radius;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $say-button-color;
  background-color: $say-button-bg-color;
  transition: background-color $say-transition, color $say-transition, border $say-transition;
  cursor: default;

  &:hover,
  &:focus {
    color: $say-button-hover-color;
    background-color: $say-button-bg-hover-color;
    border-color: $say-button-border-hover-color;
    text-decoration: none;
  }

  &:focus {
    outline: $say-outline;
    outline-offset: $say-unit-tiny/2;
  }
}

// Secondary button
.say-button--secondary {
  color: $say-button-secondary-color;
  background-color: $say-button-secondary-bg-color;
  border-color: $say-button-secondary-border-color;

  &,
  &:visited {
    color: $say-button-secondary-color;
  }

  &:hover,
  &:focus,
  &:active {
    color: $say-button-secondary-hover-color;
    background-color: $say-button-secondary-bg-hover-color;
    border-color: $say-button-secondary-border-hover-color;
  }
}

// Link button
.say-button--tertiary {
  font-size: inherit;
  color: $say-button-tertiary-color;
  background-color: $say-button-tertiary-bg-color;
  border-color: $say-button-tertiary-border-color;
  padding: 0;
  height: $say-button-tertiary-height;
  line-height: $say-button-tertiary-height;

  &,
  &:visited {
    color: $say-button-tertiary-color;
    background-color: $say-button-tertiary-bg-hover-color;
  }


  &:hover,
  &:focus,
  &:active {
    color: $say-button-tertiary-hover-color;
    background-color: $say-button-tertiary-bg-hover-color;
    border-color: $say-button-tertiary-border-hover-color;
  }
}

.say-button--tooltip {
  position: relative;
  bottom: -.3rem;
  margin-top: -1rem;

  .say-icon {
    width: 1.7rem;
    height: 1.7rem;
  }
}

.say-button__tooltip-text {
  position: relative;
  opacity: 0;
  transition: opacity $say-transition;
  margin-left: $say-unit-small;
  color: $say-neutral-700;
  background-color: $say-neutral-200;
  padding-left: $say-unit-tiny;
  padding-right: $say-unit-tiny;
  border-radius: .3rem;

  &:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba($say-white, 0);
    border-right-color: $say-neutral-200;
    border-width: .6rem;
    margin-top: -.6rem;
  }

  .say-button:hover &,
  .say-button:focus & {
    opacity: 1;
  }
}
