/* ==================================
   #SAY ARTICLE
   ================================== */

.say-article {

}

.say-article__section {
  @include say-font-size($say-h6);
  padding: $say-unit;
  display: flex;
  flex-direction: row-reverse;

  & + & {
    border-top: .1rem solid $say-neutral-200;
  }
}

.say-article__section--highlight {
  background-color: $say-neutral-100;
}

.say-article__action {
  flex-basis: 999;
  flex-grow: 1;
  text-align: right;
}

.say-article__content {
  color: $say-neutral-800;
  margin-right: $say-unit;

  > * {
    max-width: 90ch;
  }

  > * + * {
    margin-top: $say-unit-small;
  }

  h1,
  .h1 {
    @include say-font-size($say-h2-font-size,1.3);
    font-family: $say-h2-font-family;
    font-weight: $say-h2-font-weight;
  }

  h2,
  .h2 {
    @include say-font-size($say-h3-font-size,1.3);
    font-family: $say-h3-font-family;
    font-weight: $say-h3-font-weight;
  }

  h3,
  .h3 {
    @include say-font-size($say-h4-font-size);
    font-family: $say-h4-font-family;
    font-weight: $say-h4-font-weight;
  }

  h4,
  .h4 {
    @include say-font-size($say-h5-font-size);
    font-family: $say-h5-font-family;
    font-weight: $say-h5-font-weight;
  }

  h5,
  .h5 {
    @include say-font-size($say-h6-font-size);
    font-family: $say-h6-font-family;
    font-weight: $say-h6-font-weight;
  }

  h6,
  .h6 {
    @include say-font-size($say-h6-font-size);
    font-family: $say-h6-font-family;
    font-weight: $say-h6-font-weight;
  }
}

.say-article__insert {
  @include say-font-size($say-h6);
  color: $say-primary-700;
  font-family: $say-font;
  appearance: none;
  padding: 0;
  border: 0;
  display: inline-flex;
  align-items: center;
  border-radius: .2rem;
  transition: color $say-transition, background-color $say-transition;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: $say-primary-600;
  }
}
